
    import './styles.scoped.css';
    export default {
  "column-layout": "_column-layout_vj6p7_tlwt5_3",
  "grid": "_grid_vj6p7_tlwt5_13",
  "grid-no-gutters": "_grid-no-gutters_vj6p7_tlwt5_16",
  "grid-variant-text-grid": "_grid-variant-text-grid_vj6p7_tlwt5_19",
  "grid-breakpoint-default": "_grid-breakpoint-default_vj6p7_tlwt5_25",
  "grid-columns-1": "_grid-columns-1_vj6p7_tlwt5_28",
  "grid-breakpoint-xxs": "_grid-breakpoint-xxs_vj6p7_tlwt5_28",
  "grid-breakpoint-xs": "_grid-breakpoint-xs_vj6p7_tlwt5_31",
  "grid-columns-2": "_grid-columns-2_vj6p7_tlwt5_34",
  "grid-columns-3": "_grid-columns-3_vj6p7_tlwt5_40",
  "grid-columns-4": "_grid-columns-4_vj6p7_tlwt5_46",
  "grid-vertical-borders": "_grid-vertical-borders_vj6p7_tlwt5_59",
  "grid-horizontal-borders": "_grid-horizontal-borders_vj6p7_tlwt5_89",
  "root": "_root_vj6p7_tlwt5_135",
  "tile-container": "_tile-container_vj6p7_tlwt5_149",
  "has-metadata": "_has-metadata_vj6p7_tlwt5_158",
  "selected": "_selected_vj6p7_tlwt5_161",
  "disabled": "_disabled_vj6p7_tlwt5_165",
  "columns": "_columns_vj6p7_tlwt5_180",
  "column-1": "_column-1_vj6p7_tlwt5_185",
  "breakpoint-xs": "_breakpoint-xs_vj6p7_tlwt5_192",
  "breakpoint-xxs": "_breakpoint-xxs_vj6p7_tlwt5_197",
  "column-2": "_column-2_vj6p7_tlwt5_202",
  "column-3": "_column-3_vj6p7_tlwt5_219",
  "column-4": "_column-4_vj6p7_tlwt5_236",
  "control": "_control_vj6p7_tlwt5_254",
  "no-image": "_no-image_vj6p7_tlwt5_258",
  "image": "_image_vj6p7_tlwt5_262"
};
  