
    import './styles.scoped.css';
    export default {
  "root": "_root_fvjdu_1piey_3",
  "button": "_button_fvjdu_1piey_32",
  "dots": "_dots_fvjdu_1piey_33",
  "root-disabled": "_root-disabled_fvjdu_1piey_39",
  "button-disabled": "_button-disabled_fvjdu_1piey_60",
  "button-current": "_button-current_fvjdu_1piey_64",
  "page-number": "_page-number_fvjdu_1piey_75"
};
  