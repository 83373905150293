
    import './styles.scoped.css';
    export default {
  "progress": "_progress_11huc_h162d_5",
  "result-state": "_result-state_11huc_h162d_19",
  "awsui-motion-fade-in": "_awsui-motion-fade-in_11huc_h162d_1",
  "root": "_root_11huc_h162d_41",
  "with-result-button": "_with-result-button_11huc_h162d_51",
  "result-text": "_result-text_11huc_h162d_55",
  "result-container-error": "_result-container-error_11huc_h162d_59",
  "result-container-success": "_result-container-success_11huc_h162d_59",
  "result-button": "_result-button_11huc_h162d_63",
  "word-wrap": "_word-wrap_11huc_h162d_68",
  "flash": "_flash_11huc_h162d_74",
  "progress-container": "_progress-container_11huc_h162d_78",
  "percentage-container": "_percentage-container_11huc_h162d_85",
  "percentage": "_percentage_11huc_h162d_85",
  "progress-in-flash": "_progress-in-flash_11huc_h162d_128"
};
  