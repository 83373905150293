
    import './styles.scoped.css';
    export default {
  "icon": "_icon_h11ix_190jh_3",
  "size-small": "_size-small_h11ix_190jh_28",
  "size-normal": "_size-normal_h11ix_190jh_44",
  "size-big": "_size-big_h11ix_190jh_60",
  "size-large": "_size-large_h11ix_190jh_76",
  "variant-normal": "_variant-normal_h11ix_190jh_92",
  "variant-disabled": "_variant-disabled_h11ix_190jh_95",
  "variant-inverted": "_variant-inverted_h11ix_190jh_98",
  "variant-subtle": "_variant-subtle_h11ix_190jh_101",
  "variant-warning": "_variant-warning_h11ix_190jh_104",
  "variant-error": "_variant-error_h11ix_190jh_107",
  "variant-success": "_variant-success_h11ix_190jh_110",
  "variant-link": "_variant-link_h11ix_190jh_113"
};
  