
    import './styles.scoped.css';
    export default {
  "root": "_root_14iqq_owlyp_3",
  "root-disable-content-borders": "_root-disable-content-borders_14iqq_owlyp_14",
  "content": "_content_14iqq_owlyp_27",
  "content-no-paddings": "_content-no-paddings_14iqq_owlyp_27",
  "content-has-header": "_content-has-header_14iqq_owlyp_30",
  "header": "_header_14iqq_owlyp_34",
  "header-no-paddings": "_header-no-paddings_14iqq_owlyp_40",
  "header-sticky": "_header-sticky_14iqq_owlyp_43",
  "header-stuck": "_header-stuck_14iqq_owlyp_48",
  "footer": "_footer_14iqq_owlyp_64"
};
  