
    import './styles.scoped.css';
    export default {
  "root": "_root_18wu0_q7ru3_3",
  "box": "_box_18wu0_q7ru3_16",
  "p-variant": "_p-variant_18wu0_q7ru3_16",
  "color-default": "_color-default_18wu0_q7ru3_16",
  "b-variant": "_b-variant_18wu0_q7ru3_16",
  "strong-variant": "_strong-variant_18wu0_q7ru3_16",
  "code-variant": "_code-variant_18wu0_q7ru3_16",
  "pre-variant": "_pre-variant_18wu0_q7ru3_16",
  "samp-variant": "_samp-variant_18wu0_q7ru3_16",
  "h1-variant": "_h1-variant_18wu0_q7ru3_20",
  "h2-variant": "_h2-variant_18wu0_q7ru3_20",
  "h3-variant": "_h3-variant_18wu0_q7ru3_20",
  "h4-variant": "_h4-variant_18wu0_q7ru3_20",
  "h5-variant": "_h5-variant_18wu0_q7ru3_20",
  "small-variant": "_small-variant_18wu0_q7ru3_24",
  "a-variant": "_a-variant_18wu0_q7ru3_28",
  "font-size-default": "_font-size-default_18wu0_q7ru3_32",
  "font-weight-default": "_font-weight-default_18wu0_q7ru3_67",
  "color-inverted": "_color-inverted_18wu0_q7ru3_87",
  "color-text-label": "_color-text-label_18wu0_q7ru3_90",
  "color-text-body-secondary": "_color-text-body-secondary_18wu0_q7ru3_93",
  "color-text-status-error": "_color-text-status-error_18wu0_q7ru3_96",
  "color-text-status-success": "_color-text-status-success_18wu0_q7ru3_99",
  "color-text-status-info": "_color-text-status-info_18wu0_q7ru3_102",
  "color-text-status-inactive": "_color-text-status-inactive_18wu0_q7ru3_105",
  "color-inherit": "_color-inherit_18wu0_q7ru3_108",
  "font-size-body-s": "_font-size-body-s_18wu0_q7ru3_111",
  "font-size-body-m": "_font-size-body-m_18wu0_q7ru3_115",
  "font-size-heading-xs": "_font-size-heading-xs_18wu0_q7ru3_119",
  "font-size-heading-s": "_font-size-heading-s_18wu0_q7ru3_123",
  "font-size-heading-m": "_font-size-heading-m_18wu0_q7ru3_127",
  "font-size-heading-l": "_font-size-heading-l_18wu0_q7ru3_131",
  "font-size-heading-xl": "_font-size-heading-xl_18wu0_q7ru3_135",
  "font-size-display-l": "_font-size-display-l_18wu0_q7ru3_139",
  "font-weight-light": "_font-weight-light_18wu0_q7ru3_143",
  "font-weight-normal": "_font-weight-normal_18wu0_q7ru3_146",
  "font-weight-bold": "_font-weight-bold_18wu0_q7ru3_149",
  "t-left": "_t-left_18wu0_q7ru3_153",
  "t-right": "_t-right_18wu0_q7ru3_157",
  "t-center": "_t-center_18wu0_q7ru3_161",
  "p-n": "_p-n_18wu0_q7ru3_168",
  "p-top-n": "_p-top-n_18wu0_q7ru3_172",
  "p-vertical-n": "_p-vertical-n_18wu0_q7ru3_173",
  "p-right-n": "_p-right-n_18wu0_q7ru3_177",
  "p-horizontal-n": "_p-horizontal-n_18wu0_q7ru3_178",
  "p-bottom-n": "_p-bottom-n_18wu0_q7ru3_182",
  "p-left-n": "_p-left-n_18wu0_q7ru3_187",
  "p-xxxs": "_p-xxxs_18wu0_q7ru3_192",
  "p-top-xxxs": "_p-top-xxxs_18wu0_q7ru3_196",
  "p-vertical-xxxs": "_p-vertical-xxxs_18wu0_q7ru3_197",
  "p-right-xxxs": "_p-right-xxxs_18wu0_q7ru3_201",
  "p-horizontal-xxxs": "_p-horizontal-xxxs_18wu0_q7ru3_202",
  "p-bottom-xxxs": "_p-bottom-xxxs_18wu0_q7ru3_206",
  "p-left-xxxs": "_p-left-xxxs_18wu0_q7ru3_211",
  "p-xxs": "_p-xxs_18wu0_q7ru3_216",
  "p-top-xxs": "_p-top-xxs_18wu0_q7ru3_220",
  "p-vertical-xxs": "_p-vertical-xxs_18wu0_q7ru3_221",
  "p-right-xxs": "_p-right-xxs_18wu0_q7ru3_225",
  "p-horizontal-xxs": "_p-horizontal-xxs_18wu0_q7ru3_226",
  "p-bottom-xxs": "_p-bottom-xxs_18wu0_q7ru3_230",
  "p-left-xxs": "_p-left-xxs_18wu0_q7ru3_235",
  "p-xs": "_p-xs_18wu0_q7ru3_240",
  "p-top-xs": "_p-top-xs_18wu0_q7ru3_244",
  "p-vertical-xs": "_p-vertical-xs_18wu0_q7ru3_245",
  "p-right-xs": "_p-right-xs_18wu0_q7ru3_249",
  "p-horizontal-xs": "_p-horizontal-xs_18wu0_q7ru3_250",
  "p-bottom-xs": "_p-bottom-xs_18wu0_q7ru3_254",
  "p-left-xs": "_p-left-xs_18wu0_q7ru3_259",
  "p-s": "_p-s_18wu0_q7ru3_264",
  "p-top-s": "_p-top-s_18wu0_q7ru3_268",
  "p-vertical-s": "_p-vertical-s_18wu0_q7ru3_269",
  "p-right-s": "_p-right-s_18wu0_q7ru3_273",
  "p-horizontal-s": "_p-horizontal-s_18wu0_q7ru3_274",
  "p-bottom-s": "_p-bottom-s_18wu0_q7ru3_278",
  "p-left-s": "_p-left-s_18wu0_q7ru3_283",
  "p-m": "_p-m_18wu0_q7ru3_288",
  "p-top-m": "_p-top-m_18wu0_q7ru3_292",
  "p-vertical-m": "_p-vertical-m_18wu0_q7ru3_293",
  "p-right-m": "_p-right-m_18wu0_q7ru3_297",
  "p-horizontal-m": "_p-horizontal-m_18wu0_q7ru3_298",
  "p-bottom-m": "_p-bottom-m_18wu0_q7ru3_302",
  "p-left-m": "_p-left-m_18wu0_q7ru3_307",
  "p-l": "_p-l_18wu0_q7ru3_187",
  "p-top-l": "_p-top-l_18wu0_q7ru3_316",
  "p-vertical-l": "_p-vertical-l_18wu0_q7ru3_317",
  "p-right-l": "_p-right-l_18wu0_q7ru3_321",
  "p-horizontal-l": "_p-horizontal-l_18wu0_q7ru3_322",
  "p-bottom-l": "_p-bottom-l_18wu0_q7ru3_326",
  "p-left-l": "_p-left-l_18wu0_q7ru3_331",
  "p-xl": "_p-xl_18wu0_q7ru3_336",
  "p-top-xl": "_p-top-xl_18wu0_q7ru3_340",
  "p-vertical-xl": "_p-vertical-xl_18wu0_q7ru3_341",
  "p-right-xl": "_p-right-xl_18wu0_q7ru3_345",
  "p-horizontal-xl": "_p-horizontal-xl_18wu0_q7ru3_346",
  "p-bottom-xl": "_p-bottom-xl_18wu0_q7ru3_350",
  "p-left-xl": "_p-left-xl_18wu0_q7ru3_355",
  "p-xxl": "_p-xxl_18wu0_q7ru3_360",
  "p-top-xxl": "_p-top-xxl_18wu0_q7ru3_364",
  "p-vertical-xxl": "_p-vertical-xxl_18wu0_q7ru3_365",
  "p-right-xxl": "_p-right-xxl_18wu0_q7ru3_369",
  "p-horizontal-xxl": "_p-horizontal-xxl_18wu0_q7ru3_370",
  "p-bottom-xxl": "_p-bottom-xxl_18wu0_q7ru3_374",
  "p-left-xxl": "_p-left-xxl_18wu0_q7ru3_379",
  "m-n": "_m-n_18wu0_q7ru3_384",
  "m-top-n": "_m-top-n_18wu0_q7ru3_388",
  "m-vertical-n": "_m-vertical-n_18wu0_q7ru3_389",
  "m-right-n": "_m-right-n_18wu0_q7ru3_393",
  "m-horizontal-n": "_m-horizontal-n_18wu0_q7ru3_394",
  "m-bottom-n": "_m-bottom-n_18wu0_q7ru3_398",
  "m-left-n": "_m-left-n_18wu0_q7ru3_403",
  "m-xxxs": "_m-xxxs_18wu0_q7ru3_408",
  "m-top-xxxs": "_m-top-xxxs_18wu0_q7ru3_412",
  "m-vertical-xxxs": "_m-vertical-xxxs_18wu0_q7ru3_413",
  "m-right-xxxs": "_m-right-xxxs_18wu0_q7ru3_417",
  "m-horizontal-xxxs": "_m-horizontal-xxxs_18wu0_q7ru3_418",
  "m-bottom-xxxs": "_m-bottom-xxxs_18wu0_q7ru3_422",
  "m-left-xxxs": "_m-left-xxxs_18wu0_q7ru3_427",
  "m-xxs": "_m-xxs_18wu0_q7ru3_432",
  "m-top-xxs": "_m-top-xxs_18wu0_q7ru3_436",
  "m-vertical-xxs": "_m-vertical-xxs_18wu0_q7ru3_437",
  "m-right-xxs": "_m-right-xxs_18wu0_q7ru3_441",
  "m-horizontal-xxs": "_m-horizontal-xxs_18wu0_q7ru3_442",
  "m-bottom-xxs": "_m-bottom-xxs_18wu0_q7ru3_446",
  "m-left-xxs": "_m-left-xxs_18wu0_q7ru3_451",
  "m-xs": "_m-xs_18wu0_q7ru3_456",
  "m-top-xs": "_m-top-xs_18wu0_q7ru3_460",
  "m-vertical-xs": "_m-vertical-xs_18wu0_q7ru3_461",
  "m-right-xs": "_m-right-xs_18wu0_q7ru3_465",
  "m-horizontal-xs": "_m-horizontal-xs_18wu0_q7ru3_466",
  "m-bottom-xs": "_m-bottom-xs_18wu0_q7ru3_470",
  "m-left-xs": "_m-left-xs_18wu0_q7ru3_475",
  "m-s": "_m-s_18wu0_q7ru3_480",
  "m-top-s": "_m-top-s_18wu0_q7ru3_484",
  "m-vertical-s": "_m-vertical-s_18wu0_q7ru3_485",
  "m-right-s": "_m-right-s_18wu0_q7ru3_489",
  "m-horizontal-s": "_m-horizontal-s_18wu0_q7ru3_490",
  "m-bottom-s": "_m-bottom-s_18wu0_q7ru3_494",
  "m-left-s": "_m-left-s_18wu0_q7ru3_499",
  "m-m": "_m-m_18wu0_q7ru3_504",
  "m-top-m": "_m-top-m_18wu0_q7ru3_508",
  "m-vertical-m": "_m-vertical-m_18wu0_q7ru3_509",
  "m-right-m": "_m-right-m_18wu0_q7ru3_513",
  "m-horizontal-m": "_m-horizontal-m_18wu0_q7ru3_514",
  "m-bottom-m": "_m-bottom-m_18wu0_q7ru3_518",
  "m-left-m": "_m-left-m_18wu0_q7ru3_523",
  "m-l": "_m-l_18wu0_q7ru3_403",
  "m-top-l": "_m-top-l_18wu0_q7ru3_532",
  "m-vertical-l": "_m-vertical-l_18wu0_q7ru3_533",
  "m-right-l": "_m-right-l_18wu0_q7ru3_537",
  "m-horizontal-l": "_m-horizontal-l_18wu0_q7ru3_538",
  "m-bottom-l": "_m-bottom-l_18wu0_q7ru3_542",
  "m-left-l": "_m-left-l_18wu0_q7ru3_547",
  "m-xl": "_m-xl_18wu0_q7ru3_552",
  "m-top-xl": "_m-top-xl_18wu0_q7ru3_556",
  "m-vertical-xl": "_m-vertical-xl_18wu0_q7ru3_557",
  "m-right-xl": "_m-right-xl_18wu0_q7ru3_561",
  "m-horizontal-xl": "_m-horizontal-xl_18wu0_q7ru3_562",
  "m-bottom-xl": "_m-bottom-xl_18wu0_q7ru3_566",
  "m-left-xl": "_m-left-xl_18wu0_q7ru3_571",
  "m-xxl": "_m-xxl_18wu0_q7ru3_576",
  "m-top-xxl": "_m-top-xxl_18wu0_q7ru3_580",
  "m-vertical-xxl": "_m-vertical-xxl_18wu0_q7ru3_581",
  "m-right-xxl": "_m-right-xxl_18wu0_q7ru3_585",
  "m-horizontal-xxl": "_m-horizontal-xxl_18wu0_q7ru3_586",
  "m-bottom-xxl": "_m-bottom-xxl_18wu0_q7ru3_590",
  "m-left-xxl": "_m-left-xxl_18wu0_q7ru3_595",
  "d-block": "_d-block_18wu0_q7ru3_600",
  "d-inline": "_d-inline_18wu0_q7ru3_603",
  "d-inline-block": "_d-inline-block_18wu0_q7ru3_606",
  "d-none": "_d-none_18wu0_q7ru3_609",
  "f-left": "_f-left_18wu0_q7ru3_613",
  "f-right": "_f-right_18wu0_q7ru3_617"
};
  