
    import './styles.scoped.css';
    export default {
  "code-editor": "_code-editor_1gl9c_7v384_13",
  "pane": "_pane_1gl9c_7v384_244",
  "pane__close-container": "_pane__close-container_1gl9c_7v384_253",
  "pane__list": "_pane__list_1gl9c_7v384_261",
  "pane__table": "_pane__table_1gl9c_7v384_268",
  "pane__item": "_pane__item_1gl9c_7v384_273",
  "pane__item--highlighted": "_pane__item--highlighted_1gl9c_7v384_276",
  "pane__location": "_pane__location_1gl9c_7v384_282",
  "pane__description": "_pane__description_1gl9c_7v384_282",
  "focus-lock": "_focus-lock_1gl9c_7v384_297",
  "resizable-box": "_resizable-box_1gl9c_7v384_314",
  "resizable-box-handle": "_resizable-box-handle_1gl9c_7v384_319",
  "editor": "_editor_1gl9c_7v384_335",
  "status-bar": "_status-bar_1gl9c_7v384_363",
  "status-bar__left": "_status-bar__left_1gl9c_7v384_372",
  "status-bar__right": "_status-bar__right_1gl9c_7v384_379",
  "status-bar__language-mode": "_status-bar__language-mode_1gl9c_7v384_383",
  "status-bar__cursor-position": "_status-bar__cursor-position_1gl9c_7v384_383",
  "status-bar__cog-button": "_status-bar__cog-button_1gl9c_7v384_388",
  "tab-button": "_tab-button_1gl9c_7v384_395",
  "tab-button--active": "_tab-button--active_1gl9c_7v384_408",
  "tab-button--disabled": "_tab-button--disabled_1gl9c_7v384_421",
  "tab-button--errors": "_tab-button--errors_1gl9c_7v384_451",
  "tab-button--warnings": "_tab-button--warnings_1gl9c_7v384_451",
  "loading-screen": "_loading-screen_1gl9c_7v384_455",
  "error-screen": "_error-screen_1gl9c_7v384_456"
};
  