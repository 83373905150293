"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsyncContract = void 0;
var errors_1 = require("./errors");
var util_1 = require("./util");
function AsyncContract() {
    var runtypes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        runtypes[_i] = arguments[_i];
    }
    var lastIndex = runtypes.length - 1;
    var argRuntypes = runtypes.slice(0, lastIndex);
    var returnRuntype = runtypes[lastIndex];
    return {
        enforce: function (f) { return function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            if (args.length < argRuntypes.length) {
                var message = "Expected " + argRuntypes.length + " arguments but only received " + args.length;
                var failure = util_1.FAILURE.ARGUMENT_INCORRECT(message);
                throw new errors_1.ValidationError(failure);
            }
            for (var i = 0; i < argRuntypes.length; i++)
                argRuntypes[i].check(args[i]);
            var returnedPromise = f.apply(void 0, __spreadArray([], __read(args)));
            if (!(returnedPromise instanceof Promise)) {
                var message = "Expected function to return a promise, but instead got " + returnedPromise;
                var failure = util_1.FAILURE.RETURN_INCORRECT(message);
                throw new errors_1.ValidationError(failure);
            }
            return returnedPromise.then(returnRuntype.check);
        }; },
    };
}
exports.AsyncContract = AsyncContract;
