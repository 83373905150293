
    import './styles.scoped.css';
    export default {
  "content": "_content_vjswe_1j49f_3",
  "button": "_button_vjswe_1j49f_7",
  "variant-normal": "_variant-normal_vjswe_1j49f_27",
  "disabled": "_disabled_vjswe_1j49f_66",
  "variant-primary": "_variant-primary_vjswe_1j49f_74",
  "variant-link": "_variant-link_vjswe_1j49f_121",
  "variant-icon": "_variant-icon_vjswe_1j49f_168",
  "variant-flashbar-icon": "_variant-flashbar-icon_vjswe_1j49f_215",
  "variant-breadcrumb-group": "_variant-breadcrumb-group_vjswe_1j49f_262",
  "button-no-text": "_button-no-text_vjswe_1j49f_312",
  "button-no-wrap": "_button-no-wrap_vjswe_1j49f_316",
  "variant-inline-icon": "_variant-inline-icon_vjswe_1j49f_323",
  "icon-left": "_icon-left_vjswe_1j49f_328",
  "icon-right": "_icon-right_vjswe_1j49f_333",
  "icon": "_icon_vjswe_1j49f_328"
};
  