
    import './styles.scoped.css';
    export default {
  "visible-content": "_visible-content_tc96w_3z0ic_5",
  "visible-content-toggle": "_visible-content-toggle_tc96w_3z0ic_6",
  "visible-content-groups": "_visible-content-groups_tc96w_3z0ic_7",
  "visible-content-group": "_visible-content-group_tc96w_3z0ic_7",
  "visible-content-title": "_visible-content-title_tc96w_3z0ic_12",
  "visible-content-group-label": "_visible-content-group-label_tc96w_3z0ic_19",
  "visible-content-option": "_visible-content-option_tc96w_3z0ic_25",
  "visible-content-option-label": "_visible-content-option-label_tc96w_3z0ic_36",
  "root": "_root_tc96w_3z0ic_43",
  "modal-root": "_modal-root_tc96w_3z0ic_44",
  "trigger-button": "_trigger-button_tc96w_3z0ic_45",
  "cancel-button": "_cancel-button_tc96w_3z0ic_46",
  "confirm-button": "_confirm-button_tc96w_3z0ic_47",
  "custom": "_custom_tc96w_3z0ic_48",
  "second-column-small": "_second-column-small_tc96w_3z0ic_52",
  "wrap-lines": "_wrap-lines_tc96w_3z0ic_56",
  "page-size": "_page-size_tc96w_3z0ic_57",
  "page-size-form-field": "_page-size-form-field_tc96w_3z0ic_58",
  "page-size-radio-group": "_page-size-radio-group_tc96w_3z0ic_59"
};
  