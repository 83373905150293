
    import './styles.scoped.css';
    export default {
  "root": "_root_1mabk_1v3m1_3",
  "radio": "_radio_1mabk_1v3m1_14",
  "radio--has-description": "_radio--has-description_1mabk_1v3m1_22",
  "radio-control": "_radio-control_1mabk_1v3m1_26",
  "input": "_input_1mabk_1v3m1_34",
  "styled-circle-border": "_styled-circle-border_1mabk_1v3m1_53",
  "styled-circle-disabled": "_styled-circle-disabled_1mabk_1v3m1_57",
  "styled-circle-fill": "_styled-circle-fill_1mabk_1v3m1_62",
  "styled-circle-checked": "_styled-circle-checked_1mabk_1v3m1_68"
};
  