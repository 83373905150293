
    import './styles.scoped.css';
    export default {
  "tutorial-list": "_tutorial-list_1u70p_11t5q_3",
  "tutorial-title": "_tutorial-title_1u70p_11t5q_15",
  "task": "_task_1u70p_11t5q_22",
  "task-title": "_task-title_1u70p_11t5q_30",
  "current-task": "_current-task_1u70p_11t5q_35",
  "successful-task": "_successful-task_1u70p_11t5q_39",
  "congratulation-message": "_congratulation-message_1u70p_11t5q_43",
  "plaintext-congratulation-description": "_plaintext-congratulation-description_1u70p_11t5q_51",
  "divider": "_divider_1u70p_11t5q_55",
  "step-list": "_step-list_1u70p_11t5q_59",
  "step": "_step_1u70p_11t5q_59",
  "expandable-section-wrapper": "_expandable-section-wrapper_1u70p_11t5q_68"
};
  