
    import './styles.scoped.css';
    export default {
  "empty": "_empty_n4qlp_fbnq3_3",
  "awsui-motion-fade-in": "_awsui-motion-fade-in_n4qlp_fbnq3_1",
  "root": "_root_n4qlp_fbnq3_25",
  "row": "_row_n4qlp_fbnq3_42",
  "row-control": "_row-control_n4qlp_fbnq3_46",
  "field": "_field_n4qlp_fbnq3_50",
  "add-button": "_add-button_n4qlp_fbnq3_54",
  "remove-button": "_remove-button_n4qlp_fbnq3_58",
  "button-container": "_button-container_n4qlp_fbnq3_62",
  "divider": "_divider_n4qlp_fbnq3_66",
  "additional-info": "_additional-info_n4qlp_fbnq3_70"
};
  