
    import './styles.scoped.css';
    export default {
  "column-layout": "_column-layout_vvxn7_13n55_3",
  "grid": "_grid_vvxn7_13n55_13",
  "grid-no-gutters": "_grid-no-gutters_vvxn7_13n55_16",
  "grid-variant-text-grid": "_grid-variant-text-grid_vvxn7_13n55_19",
  "grid-breakpoint-default": "_grid-breakpoint-default_vvxn7_13n55_25",
  "grid-columns-1": "_grid-columns-1_vvxn7_13n55_28",
  "grid-breakpoint-xxs": "_grid-breakpoint-xxs_vvxn7_13n55_28",
  "grid-breakpoint-xs": "_grid-breakpoint-xs_vvxn7_13n55_31",
  "grid-columns-2": "_grid-columns-2_vvxn7_13n55_34",
  "grid-columns-3": "_grid-columns-3_vvxn7_13n55_40",
  "grid-columns-4": "_grid-columns-4_vvxn7_13n55_46",
  "grid-vertical-borders": "_grid-vertical-borders_vvxn7_13n55_59",
  "grid-horizontal-borders": "_grid-horizontal-borders_vvxn7_13n55_89"
};
  