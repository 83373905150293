
    import './styles.scoped.css';
    export default {
  "item-element": "_item-element_93a1u_1kjp4_3",
  "disabled": "_disabled_93a1u_1kjp4_16",
  "last": "_last_93a1u_1kjp4_25",
  "highlighted": "_highlighted_93a1u_1kjp4_28",
  "has-category-header": "_has-category-header_93a1u_1kjp4_38",
  "item-link": "_item-link_93a1u_1kjp4_41"
};
  