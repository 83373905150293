
    import './styles.scoped.css';
    export default {
  "wrapper": "_wrapper_cqcqk_ejf7k_3",
  "elementWrapper": "_elementWrapper_cqcqk_ejf7k_7",
  "markerWrapper": "_markerWrapper_cqcqk_ejf7k_11",
  "placement-right": "_placement-right_cqcqk_ejf7k_16",
  "placement-left": "_placement-left_cqcqk_ejf7k_21",
  "inlineWrapper": "_inlineWrapper_cqcqk_ejf7k_26"
};
  