
    import './styles.scoped.css';
    export default {
  "option": "_option_1p2cx_1jgan_3",
  "disabled": "_disabled_1p2cx_1jgan_18",
  "content": "_content_1p2cx_1jgan_22",
  "label-content": "_label-content_1p2cx_1jgan_29",
  "label": "_label_1p2cx_1jgan_29",
  "tag": "_tag_1p2cx_1jgan_36",
  "label-tag": "_label-tag_1p2cx_1jgan_37",
  "label-prefix": "_label-prefix_1p2cx_1jgan_48",
  "tags": "_tags_1p2cx_1jgan_61",
  "description": "_description_1p2cx_1jgan_62",
  "icon": "_icon_1p2cx_1jgan_78",
  "icon-size-big": "_icon-size-big_1p2cx_1jgan_85",
  "filtering-match-highlight": "_filtering-match-highlight_1p2cx_1jgan_89",
  "trigger-variant": "_trigger-variant_1p2cx_1jgan_104"
};
  