
    import './styles.scoped.css';
    export default {
  "root": "_root_2qdw9_1yaee_3",
  "root-no-actions": "_root-no-actions_2qdw9_1yaee_17",
  "root-variant-h2": "_root-variant-h2_2qdw9_1yaee_17",
  "root-variant-h3": "_root-variant-h3_2qdw9_1yaee_17",
  "root-has-description": "_root-has-description_2qdw9_1yaee_17",
  "main": "_main_2qdw9_1yaee_21",
  "main-variant-h1": "_main-variant-h1_2qdw9_1yaee_29",
  "main-variant-h2": "_main-variant-h2_2qdw9_1yaee_32",
  "main-variant-h3": "_main-variant-h3_2qdw9_1yaee_32",
  "actions": "_actions_2qdw9_1yaee_39",
  "actions-variant-h1": "_actions-variant-h1_2qdw9_1yaee_43",
  "actions-variant-h2": "_actions-variant-h2_2qdw9_1yaee_46",
  "actions-variant-h3": "_actions-variant-h3_2qdw9_1yaee_46",
  "title": "_title_2qdw9_1yaee_53",
  "title-variant-h2": "_title-variant-h2_2qdw9_1yaee_59",
  "title-variant-h3": "_title-variant-h3_2qdw9_1yaee_59",
  "info": "_info_2qdw9_1yaee_66",
  "description": "_description_2qdw9_1yaee_70",
  "description-variant-h1": "_description-variant-h1_2qdw9_1yaee_75",
  "description-variant-h2": "_description-variant-h2_2qdw9_1yaee_80",
  "description-variant-h3": "_description-variant-h3_2qdw9_1yaee_80",
  "heading": "_heading_2qdw9_1yaee_86",
  "heading-variant-h1": "_heading-variant-h1_2qdw9_1yaee_96",
  "heading-variant-h2": "_heading-variant-h2_2qdw9_1yaee_100",
  "heading-text": "_heading-text_2qdw9_1yaee_104",
  "counter": "_counter_2qdw9_1yaee_108"
};
  