
    import './styles.scoped.css';
    export default {
  "flashbar": "_flashbar_1q84n_1uah8_3",
  "flash": "_flash_1q84n_1uah8_3",
  "flash-body": "_flash-body_1q84n_1uah8_38",
  "flash-icon": "_flash-icon_1q84n_1uah8_44",
  "flash-message": "_flash-message_1q84n_1uah8_49",
  "flash-header": "_flash-header_1q84n_1uah8_58",
  "flash-content": "_flash-content_1q84n_1uah8_62",
  "dismiss-button-wrapper": "_dismiss-button-wrapper_1q84n_1uah8_66",
  "dismiss-button": "_dismiss-button_1q84n_1uah8_66",
  "action-button-wrapper": "_action-button-wrapper_1q84n_1uah8_76",
  "breakpoint-default": "_breakpoint-default_1q84n_1uah8_82",
  "action-button": "_action-button_1q84n_1uah8_76",
  "flash-type-success": "_flash-type-success_1q84n_1uah8_93",
  "flash-type-error": "_flash-type-error_1q84n_1uah8_97",
  "flash-type-warning": "_flash-type-warning_1q84n_1uah8_101",
  "flash-type-info": "_flash-type-info_1q84n_1uah8_105"
};
  