
    import './styles.scoped.css';
    export default {
  "root": "_root_1612d_44z7j_33",
  "spinner-rotator": "_spinner-rotator_1612d_44z7j_1",
  "size-normal": "_size-normal_1612d_44z7j_41",
  "size-big": "_size-big_1612d_44z7j_49",
  "size-large": "_size-large_1612d_44z7j_57",
  "variant-normal": "_variant-normal_1612d_44z7j_65",
  "variant-disabled": "_variant-disabled_1612d_44z7j_68",
  "variant-inverted": "_variant-inverted_1612d_44z7j_71",
  "circle": "_circle_1612d_44z7j_75",
  "circle-left": "_circle-left_1612d_44z7j_99",
  "spinner-line-left": "_spinner-line-left_1612d_44z7j_1",
  "circle-right": "_circle-right_1612d_44z7j_104",
  "spinner-line-right": "_spinner-line-right_1612d_44z7j_1"
};
  