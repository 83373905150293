
    import './styles.scoped.css';
    export default {
  "root": "_root_1xupv_1k1ti_3",
  "wizard": "_wizard_1xupv_1k1ti_13",
  "form": "_form_1xupv_1k1ti_17",
  "form-component": "_form-component_1xupv_1k1ti_24",
  "form-header": "_form-header_1xupv_1k1ti_25",
  "collapsed-steps": "_collapsed-steps_1xupv_1k1ti_29",
  "navigation": "_navigation_1xupv_1k1ti_33",
  "hidden": "_hidden_1xupv_1k1ti_42",
  "navigation-links": "_navigation-links_1xupv_1k1ti_46",
  "navigation-link": "_navigation-link_1xupv_1k1ti_46",
  "navigation-link-item": "_navigation-link-item_1xupv_1k1ti_63",
  "navigation-link-label": "_navigation-link-label_1xupv_1k1ti_64",
  "navigation-link-active": "_navigation-link-active_1xupv_1k1ti_68",
  "navigation-link-disabled": "_navigation-link-disabled_1xupv_1k1ti_73",
  "cancel-button": "_cancel-button_1xupv_1k1ti_77",
  "previous-button": "_previous-button_1xupv_1k1ti_78",
  "primary-button": "_primary-button_1xupv_1k1ti_79"
};
  