
    import './styles.scoped.css';
    export default {
  "root": "_root_18582_mrz62_3",
  "child": "_child_18582_mrz62_7",
  "horizontal": "_horizontal_18582_mrz62_14",
  "horizontal-xxxs": "_horizontal-xxxs_18582_mrz62_18",
  "horizontal-xxs": "_horizontal-xxs_18582_mrz62_22",
  "horizontal-xs": "_horizontal-xs_18582_mrz62_26",
  "horizontal-s": "_horizontal-s_18582_mrz62_30",
  "horizontal-m": "_horizontal-m_18582_mrz62_34",
  "horizontal-l": "_horizontal-l_18582_mrz62_38",
  "horizontal-xl": "_horizontal-xl_18582_mrz62_42",
  "horizontal-xxl": "_horizontal-xxl_18582_mrz62_46",
  "child-horizontal-xxxs": "_child-horizontal-xxxs_18582_mrz62_51",
  "child-horizontal-xxs": "_child-horizontal-xxs_18582_mrz62_57",
  "child-horizontal-xs": "_child-horizontal-xs_18582_mrz62_63",
  "child-horizontal-s": "_child-horizontal-s_18582_mrz62_69",
  "child-horizontal-m": "_child-horizontal-m_18582_mrz62_75",
  "child-horizontal-l": "_child-horizontal-l_18582_mrz62_81",
  "child-horizontal-xl": "_child-horizontal-xl_18582_mrz62_87",
  "child-horizontal-xxl": "_child-horizontal-xxl_18582_mrz62_93",
  "vertical": "_vertical_18582_mrz62_102",
  "child-vertical-xxxs": "_child-vertical-xxxs_18582_mrz62_106",
  "child-vertical-xxs": "_child-vertical-xxs_18582_mrz62_110",
  "child-vertical-xs": "_child-vertical-xs_18582_mrz62_114",
  "child-vertical-s": "_child-vertical-s_18582_mrz62_118",
  "child-vertical-m": "_child-vertical-m_18582_mrz62_122",
  "child-vertical-l": "_child-vertical-l_18582_mrz62_126",
  "child-vertical-xl": "_child-vertical-xl_18582_mrz62_130",
  "child-vertical-xxl": "_child-vertical-xxl_18582_mrz62_134"
};
  