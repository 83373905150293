
    import './styles.scoped.css';
    export default {
  "alert": "_alert_mx3cw_j1xyn_3",
  "awsui-motion-fade-in": "_awsui-motion-fade-in_mx3cw_j1xyn_1",
  "root": "_root_mx3cw_j1xyn_25",
  "hidden": "_hidden_mx3cw_j1xyn_38",
  "body": "_body_mx3cw_j1xyn_60",
  "header": "_header_mx3cw_j1xyn_66",
  "action": "_action_mx3cw_j1xyn_70",
  "action-button": "_action-button_mx3cw_j1xyn_75",
  "breakpoint-default": "_breakpoint-default_mx3cw_j1xyn_79",
  "message": "_message_mx3cw_j1xyn_86",
  "content": "_content_mx3cw_j1xyn_92",
  "dismiss": "_dismiss_mx3cw_j1xyn_96",
  "dismiss-button": "_dismiss-button_mx3cw_j1xyn_101",
  "dismiss-with-button": "_dismiss-with-button_mx3cw_j1xyn_105",
  "icon": "_icon_mx3cw_j1xyn_109",
  "type-error": "_type-error_mx3cw_j1xyn_115",
  "type-warning": "_type-warning_mx3cw_j1xyn_123",
  "type-success": "_type-success_mx3cw_j1xyn_131",
  "type-info": "_type-info_mx3cw_j1xyn_139"
};
  