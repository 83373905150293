
    import './styles.scoped.css';
    export default {
  "root": "_root_1fn7j_glnlb_3",
  "styled-box": "_styled-box_1fn7j_glnlb_10",
  "styled-box-checked": "_styled-box-checked_1fn7j_glnlb_16",
  "styled-box-indeterminate": "_styled-box-indeterminate_1fn7j_glnlb_16",
  "styled-box-disabled": "_styled-box-disabled_1fn7j_glnlb_20",
  "styled-line": "_styled-line_1fn7j_glnlb_34",
  "styled-line-disabled": "_styled-line-disabled_1fn7j_glnlb_39"
};
  