
    import './styles.scoped.css';
    export default {
  "calendar": "_calendar_145pa_1j3bt_3",
  "awsui-motion-fade-in-0": "_awsui-motion-fade-in-0_145pa_1j3bt_1",
  "root": "_root_145pa_1j3bt_26",
  "date-picker-container": "_date-picker-container_145pa_1j3bt_36",
  "date-picker-trigger": "_date-picker-trigger_145pa_1j3bt_41",
  "date-picker-input": "_date-picker-input_145pa_1j3bt_45",
  "open-calendar-button": "_open-calendar-button_145pa_1j3bt_50",
  "calendar-inner": "_calendar-inner_145pa_1j3bt_58",
  "calendar-header": "_calendar-header_145pa_1j3bt_61",
  "calendar-header-month": "_calendar-header-month_145pa_1j3bt_66",
  "calendar-next-month-btn": "_calendar-next-month-btn_145pa_1j3bt_72",
  "calendar-prev-month-btn": "_calendar-prev-month-btn_145pa_1j3bt_75",
  "calendar-day-names": "_calendar-day-names_145pa_1j3bt_78",
  "calendar-day-name": "_calendar-day-name_145pa_1j3bt_78",
  "calendar-dates": "_calendar-dates_145pa_1j3bt_92",
  "calendar-week": "_calendar-week_145pa_1j3bt_95",
  "calendar-day": "_calendar-day_145pa_1j3bt_78",
  "calendar-day-in-last-week": "_calendar-day-in-last-week_145pa_1j3bt_113",
  "calendar-day-focusable": "_calendar-day-focusable_145pa_1j3bt_116",
  "calendar-day-enabled": "_calendar-day-enabled_145pa_1j3bt_119",
  "calendar-day-current-month": "_calendar-day-current-month_145pa_1j3bt_123",
  "calendar-day-selected": "_calendar-day-selected_145pa_1j3bt_130",
  "calendar-day-today": "_calendar-day-today_145pa_1j3bt_133"
};
  