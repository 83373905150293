
    import './styles.scoped.css';
    export default {
  "wrapper": "_wrapper_1wepg_zxjdx_3",
  "label-wrapper": "_label-wrapper_1wepg_zxjdx_10",
  "content": "_content_1wepg_zxjdx_15",
  "empty-content": "_empty-content_1wepg_zxjdx_21",
  "description": "_description_1wepg_zxjdx_25",
  "description-bottom-padding": "_description-bottom-padding_1wepg_zxjdx_30",
  "label": "_label_1wepg_zxjdx_10",
  "label-disabled": "_label-disabled_1wepg_zxjdx_38",
  "description-disabled": "_description-disabled_1wepg_zxjdx_39",
  "control": "_control_1wepg_zxjdx_43",
  "outline": "_outline_1wepg_zxjdx_46"
};
  