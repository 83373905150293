
    import './styles.scoped.css';
    export default {
  "arrow": "_arrow_1hpp3_gbda8_3",
  "arrow-outer": "_arrow-outer_1hpp3_gbda8_7",
  "arrow-inner": "_arrow-inner_1hpp3_gbda8_7",
  "arrow-position-right-top": "_arrow-position-right-top_1hpp3_gbda8_38",
  "arrow-position-right-bottom": "_arrow-position-right-bottom_1hpp3_gbda8_38",
  "arrow-position-left-top": "_arrow-position-left-top_1hpp3_gbda8_41",
  "arrow-position-left-bottom": "_arrow-position-left-bottom_1hpp3_gbda8_41",
  "arrow-position-top-center": "_arrow-position-top-center_1hpp3_gbda8_44",
  "arrow-position-top-responsive": "_arrow-position-top-responsive_1hpp3_gbda8_44",
  "arrow-position-bottom-center": "_arrow-position-bottom-center_1hpp3_gbda8_47",
  "arrow-position-bottom-responsive": "_arrow-position-bottom-responsive_1hpp3_gbda8_47",
  "annotation": "_annotation_1hpp3_gbda8_51",
  "action-buttons": "_action-buttons_1hpp3_gbda8_55",
  "description": "_description_1hpp3_gbda8_59",
  "actionBar": "_actionBar_1hpp3_gbda8_64",
  "stepCounter": "_stepCounter_1hpp3_gbda8_71",
  "divider": "_divider_1hpp3_gbda8_75",
  "hotspot": "_hotspot_1hpp3_gbda8_79"
};
  