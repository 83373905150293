
    import './styles.scoped.css';
    export default {
  "root": "_root_4yi2u_db2mu_3",
  "input": "_input_4yi2u_db2mu_14",
  "toggle-control": "_toggle-control_4yi2u_db2mu_33",
  "toggle-control-checked": "_toggle-control-checked_4yi2u_db2mu_42",
  "toggle-control-disabled": "_toggle-control-disabled_4yi2u_db2mu_45",
  "toggle-handle": "_toggle-handle_4yi2u_db2mu_52",
  "toggle-handle-checked": "_toggle-handle-checked_4yi2u_db2mu_63",
  "toggle-handle-disabled": "_toggle-handle-disabled_4yi2u_db2mu_66"
};
  