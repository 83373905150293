
    import './styles.scoped.css';
    export default {
  "uxdg-inverted": "_uxdg-inverted_1cbgc_1xy9s_3",
  "root": "_root_1cbgc_1xy9s_7",
  "status-error": "_status-error_1cbgc_1xy9s_14",
  "status-warning": "_status-warning_1cbgc_1xy9s_17",
  "status-success": "_status-success_1cbgc_1xy9s_20",
  "status-info": "_status-info_1cbgc_1xy9s_23",
  "status-stopped": "_status-stopped_1cbgc_1xy9s_26",
  "status-pending": "_status-pending_1cbgc_1xy9s_29",
  "status-in-progress": "_status-in-progress_1cbgc_1xy9s_32",
  "status-loading": "_status-loading_1cbgc_1xy9s_35",
  "color-override-red": "_color-override-red_1cbgc_1xy9s_38",
  "color-override-grey": "_color-override-grey_1cbgc_1xy9s_41",
  "color-override-blue": "_color-override-blue_1cbgc_1xy9s_44",
  "color-override-green": "_color-override-green_1cbgc_1xy9s_47",
  "icon": "_icon_1cbgc_1xy9s_50",
  "container": "_container_1cbgc_1xy9s_54"
};
  