
    import './styles.scoped.css';
    export default {
  "dialog": "_dialog_1d2i7_j7i5k_11",
  "modal-slide-up": "_modal-slide-up_1d2i7_j7i5k_1",
  "awsui-motion-fade-in-0": "_awsui-motion-fade-in-0_1d2i7_j7i5k_1",
  "overlay": "_overlay_1d2i7_j7i5k_35",
  "awsui-motion-fade-in": "_awsui-motion-fade-in_1d2i7_j7i5k_1",
  "root": "_root_1d2i7_j7i5k_57",
  "hidden": "_hidden_1d2i7_j7i5k_78",
  "focus-lock": "_focus-lock_1d2i7_j7i5k_92",
  "small": "_small_1d2i7_j7i5k_110",
  "medium": "_medium_1d2i7_j7i5k_113",
  "large": "_large_1d2i7_j7i5k_116",
  "max": "_max_1d2i7_j7i5k_119",
  "breakpoint-xs": "_breakpoint-xs_1d2i7_j7i5k_119",
  "container": "_container_1d2i7_j7i5k_124",
  "content": "_content_1d2i7_j7i5k_146",
  "inner-header": "_inner-header_1d2i7_j7i5k_150",
  "header": "_header_1d2i7_j7i5k_155",
  "header--text": "_header--text_1d2i7_j7i5k_161",
  "footer": "_footer_1d2i7_j7i5k_165",
  "dismiss-control": "_dismiss-control_1d2i7_j7i5k_175",
  "modal-open": "_modal-open_1d2i7_j7i5k_179"
};
  