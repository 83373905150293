
    import './styles.scoped.css';
    export default {
  "content-enter": "_content-enter_ig8mp_16axx_3",
  "awsui-motion-fade-in": "_awsui-motion-fade-in_ig8mp_16axx_1",
  "tutorial-list": "_tutorial-list_ig8mp_16axx_25",
  "tutorial-box": "_tutorial-box_ig8mp_16axx_37",
  "tutorial-box-title": "_tutorial-box-title_ig8mp_16axx_53",
  "tutorial-description-plaintext": "_tutorial-description-plaintext_ig8mp_16axx_59",
  "tutorial-description": "_tutorial-description_ig8mp_16axx_59",
  "expandable-section": "_expandable-section_ig8mp_16axx_70",
  "expanded": "_expanded_ig8mp_16axx_74",
  "button-wrapper": "_button-wrapper_ig8mp_16axx_78",
  "start": "_start_ig8mp_16axx_82",
  "expand-button": "_expand-button_ig8mp_16axx_86",
  "collapse-button": "_collapse-button_ig8mp_16axx_90",
  "learn-more-link": "_learn-more-link_ig8mp_16axx_94",
  "download-link": "_download-link_ig8mp_16axx_98"
};
  